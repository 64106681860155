import React from 'react'
import CustomizedDataGrid from '../../../dashboard/components/CustomizedDataGrid'
import FormDialog from '../../../components/FormDialog'
import { CREATE } from '../../../utils/constant'
import { parcelTypeFormFields } from '../../../utils/form-fields'

function ParcelTypes({
    openCreateParcelTypeDialog,
    setOpenCreateParcelTypeDialog
}) {
    const values = [
        {
            id: 0,
            parcel_type: "",
        },
    ];

    return (
        <>
            {openCreateParcelTypeDialog && (
                <FormDialog
                    open={openCreateParcelTypeDialog}
                    handleClose={() => setOpenCreateParcelTypeDialog(false)}
                    dialogTitle={"Parcel Type"}
                    action={CREATE}
                    fields={parcelTypeFormFields}
                    values={values}
                    url={""}
                />
            )}
            <CustomizedDataGrid />
        </>
    )
}

export default ParcelTypes