import React, { useState } from 'react'
import Dashboard from '../../dashboard/Dashboard'
import { Box, Button, Typography } from '@mui/material'
import CustomizedDataGrid from '../../dashboard/components/CustomizedDataGrid'
import CustomTabs from '../../components/CustomTabs'
import FormDialog from '../../components/FormDialog'
import { Add } from '@mui/icons-material'
import { rowDirectionSpaceBetween } from '../../utils/styles'
import { userFormFields } from '../../utils/form-fields'
import { CREATE } from '../../utils/constant'
import dayjs from 'dayjs'

const orderStatus = [
    { label: "All", value: "All" },
    { label: "Users", value: "Users" },
    { label: "Agents", value: "Agents" },
    { label: "Drivers", value: "Drivers" },
    { label: "Workers", value: "Workers" },
    { label: "Admins", value: "Admins" },
];

function Users() {
    const [currentTab, setCurrentTab] = useState(orderStatus[0].value);
    const [openCreateUserDialog, setOpenCreateUserDialog] = React.useState(false);
    const values = [
        {
            id: 0,
            first_name: "",
            second_name: "",
            username: "",
            email: "",
            phone_no: "",
            gender: "",
            date_of_birth: dayjs(''),
            role: "",
        },
    ];

    const onClickTab = (item) => {
        setCurrentTab(item.value);
    }

    return (
        <Dashboard headerTitle='Users'>
            {openCreateUserDialog && (
                <FormDialog
                    open={openCreateUserDialog}
                    handleClose={() => setOpenCreateUserDialog(false)}
                    dialogTitle={"User"}
                    action={CREATE}
                    fields={userFormFields}
                    values={values}
                    url={""}
                />
            )}
            <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
                    Users
                </Typography>
                <Box
                    sx={rowDirectionSpaceBetween}
                >
                    <CustomTabs
                        tabs={orderStatus}
                        currectTab={currentTab}
                        onClick={onClickTab}
                    />
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<Add />}
                        onClick={() => {
                            setOpenCreateUserDialog(true);
                        }}
                    >
                        Add
                    </Button>
                </Box>
                <CustomizedDataGrid />
            </Box>
        </Dashboard>
    )
}

export default Users