import React from 'react'
import CustomizedDataGrid from '../../../dashboard/components/CustomizedDataGrid'
import FormDialog from '../../../components/FormDialog'
import { CREATE } from '../../../utils/constant'
import { regionFormFields } from '../../../utils/form-fields'

function Regions({
    openCreateRegionDialog,
    setOpenCreateRegionDialog
}) {
    const values = [
        {
            id: 0,
            region: "",
        },
    ];

    return (
        <>
            {openCreateRegionDialog && (
                <FormDialog
                    open={openCreateRegionDialog}
                    handleClose={() => setOpenCreateRegionDialog(false)}
                    dialogTitle={"Region"}
                    action={CREATE}
                    fields={regionFormFields}
                    values={values}
                    url={""}
                />
            )}
            <CustomizedDataGrid />
        </>
    )
}

export default Regions