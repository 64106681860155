import React, { useState } from 'react'
import Dashboard from '../../dashboard/Dashboard'
import { Box, Typography } from '@mui/material'
import CustomizedDataGrid from '../../dashboard/components/CustomizedDataGrid'
import CustomTabs from '../../components/CustomTabs'

const orderStatus = [
    { label: "All", value: "All" },
    { label: "Pending", value: "Pending" },
    { label: "Paid", value: "Paid" },
    { label: "In transit", value: "In-transit" },
    { label: "My return", value: "My-return" },
    { label: "Cancelled", value: "Cancelled" },
];

function Orders() {
    const [currentTab, setCurrentTab] = useState("All");

    const onClickTab = (item) => {
        setCurrentTab(item.value);
    }

    return (
        <Dashboard headerTitle='Orders'>
            <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
                <Typography component="h2" variant="h6">
                    Orders
                </Typography>
                <CustomTabs
                    tabs={orderStatus}
                    currectTab={currentTab}
                    onClick={onClickTab}
                />
                <CustomizedDataGrid />
            </Box>
        </Dashboard>
    )
}

export default Orders